.nav {
    border-bottom: #ececec solid 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 70px;
}

.logo img {
    height: 100%;
}
