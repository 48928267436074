.navbar_nav__KHMn2 {
    border-bottom: #ececec solid 1px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.navbar_logo__6tXHF {
    height: 70px;
}

.navbar_logo__6tXHF img {
    height: 100%;
}

.home_topSection__530F8 {
    position: relative;
}
.home_topSectionImage__2jI4G {
    width: 100%;
}
.home_card__27Dsd {
    font-family: 'mugglenewsregular';
    background-color: rgba(93, 109, 91, 0.95);
    color: #fff;
    padding: 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 16px;
}

.home_card__27Dsd h1 {
    font-size: 2.8rem;
}
.home_card__27Dsd p {
    font-size: 2rem;
}

body,
html,
#root,
.appWrapper {
    height: 100%;
}
html {
    height: auto;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 5, 2019 */



@font-face {
    font-family: 'mugglenewsregular';
    src: url(/static/media/mugglenews_by_nathanthenerd-d41qn8p-webfont.cd834fb2.woff2) format('woff2'),
         url(/static/media/mugglenews_by_nathanthenerd-d41qn8p-webfont.90da284c.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Northwell Alt';
    src: url(/static/media/NorthwellAlt.2e996b0c.woff2) format('woff2'),
        url(/static/media/NorthwellAlt.ce69bc3e.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}


